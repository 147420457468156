<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Nome"
            :required="true"
            v-model="form.title"
            :value="form.title"
          ></input-form-text>
          <div class="form-group">
            <select-form
              v-model="form.ativo"
              :value="form.ativo"
              name="Ativo"
              url="/arquivos?cat=1"
              id="id"
              titulo="nome"
              layout="true"
              optionText="Selecione"
              required="true"
            ></select-form>
          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import SelectForm from '@/components/form/SelectForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading,
    SelectForm
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Cobertura',
      form: {
        title: '',
        ativo: null
      },
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      this.isActive = true
      const body = this.form
      body.title = body.title.trim()
      if (this.$route.params.id) {
        await Services.put(`/cobertura/${this.$route.params.id}`, body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/cobertura')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        await Services.post('/cobertura', body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/cobertura')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
      this.isActive = false
    },
    async getDados() {
      const dados = await Services.get(
        `/cobertura/${this.$route.params.id}`
      ).catch(response => {
        console.log('error: ' + response)
        return (this.isActive = false)
      })
      this.form.title = dados.title
      this.form.ativo = dados.ativo
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
